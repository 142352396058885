import LazyLoading from "@grua/core/LazyLoading";
import { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import FallbackSwitch from "_common/components/fallback-switch";

const AuthModuleRoutes = lazy(() => import("modules/auth/AuthModuleRoutes"));
const HomeModuleRoutes = lazy(() => import("modules/home/HomeModuleRoutes"));

const MainRoutes = () => (
  <FallbackSwitch>
    <Route path="/applications" component={LazyLoading(HomeModuleRoutes)} />
    <Route path="/auth" component={LazyLoading(AuthModuleRoutes)} />

    {/* rotas privadas */}

    {/* NAO ALTERAR A POSIÇÃO/CONFIGURAÇÃO ROTA "/" */}
    <Redirect to="/auth/profile" />
  </FallbackSwitch>
);

export default MainRoutes;
